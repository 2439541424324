<template>
    <div class="item_wrapper">
        <div class="dots"></div>
        <img :src="this.img" :alt="title">

        <div class="title">
            <p>{{title}}</p>
        </div>
        <div class="description" v-html='description'></div>
    </div>

</template>

<script>
    export default {
        props: ['img', 'title', 'description'],
        name: "benefitsItem"
    }
</script>

<style scoped lang="scss">
    @import "../../assets/scss/index";

    .item_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 210px;
        text-align: center;
        opacity: 0.8;
        transition: $transitionLink;

        @media (max-width: 575px) {
            margin: 10px 5px;
        }

        @media (max-width: 479px) {
            margin: 20px 5px;
        }


        &:hover {
            transition: $transitionLink;
            transform: $transformItem;
            opacity: 1;
        }

        & img {
            object-fit: contain;
            width: 93px;
            height: 93px;

            @media (max-width: 769px) {
                width: 73px;
                height: 73px;
            }
        }

        & .title {
            margin-top: 5px;

            & p {
                @include titleItem;
            }
        }

        & .description {
            & p {
                @include descriptionItem;
            }
        }


    }
</style>