<template>
  <div class="wrapper_about"
       v-if="this.page_settings"
  >

    <loading v-if="!this.$store.getters.loading" />

    <headerBg data-aos-duration="2000" data-aos-delay="200" data-aos="fade-up" :white="true"
      :subtitle="data.page_settings.about_subtitle" :title="data.page_settings.about_title"
      :description="data.page_settings.about_description" :btn="false" :down="true"
      :bg="'https://api.grunchem.eu/storage/' + data.page_settings.about_bg" />

    <div class="about">

      <section-rigt-left data-aos-duration="2000" data-aos-delay="200" data-aos="zoom-in"
        :img="'https://api.grunchem.eu/storage/' + data.page_settings.labaratories_image"
        :subtitle="data.page_settings.labaratories_subtitle" :title="data.page_settings.labaratories_title"
        :description="data.page_settings.labaratories_description" />


      <benefits-wrapper data-aos-duration="2000" data-aos-delay="200" data-aos="zoom-in"
        :title="data.page_settings.benefits_title" :description="data.page_settings.benefits_description">

        <benefits-item data-aos-duration="2000" data-aos-delay="200" data-aos="zoom-in"
          v-for="benefits in data.benefits" :key="benefits.title" :title="benefits.title"
          :description="benefits.description" :img="benefits.media.icon[0].icon_90_90" />
      </benefits-wrapper>


      <section-rigt-left data-aos-duration="2000" data-aos-delay="200" data-aos="zoom-in" :reverse="true"
        :img="'https://api.grunchem.eu/storage/' + data.page_settings.support_image"
        :title="data.page_settings.support_title" :description="data.page_settings.support_description" />

      <section-rigt-left data-aos-duration="2000" data-aos-delay="200" data-aos="zoom-in"
        :img="'https://api.grunchem.eu/storage/' + data.page_settings.patent_image"
        :title="data.page_settings.patent_title" :description="data.page_settings.patent_description"
        :btnPatent="data.header_footer.vocabulary.patent_link" :btnTitle="data.header_footer.vocabulary.views_patent" />

      <section-rigt-left data-aos-duration="2000" data-aos-delay="200" data-aos="zoom-in" :reverse="true"
        :img="'https://api.grunchem.eu/storage/' + data.page_settings.map_image" :title="data.page_settings.map_title"
        :description="data.page_settings.map_description" />


      <benefits-wrapper data-aos-duration="2000" data-aos-delay="200" data-aos="zoom-in"
        :title="data.page_settings.lab_title" :description="data.page_settings.lab_description">
        <div class="item">

          <partnersItem data-aos-duration="2000" data-aos-delay="200" data-aos="zoom-in"
            v-for="partners in data.laboratories" :key="partners.title" :img="partners.media.image[0].default"
            :title="partners.title" />

        </div>
      </benefits-wrapper>

    </div>
  </div>
</template>

<script>
  import loading from '../components/loading'
  import {
    RepositoryFactory
  } from "../api/RepositoryFactory";
  const GetRepository = RepositoryFactory.get('data');


  import partnersItem from "../components/partners/partnersItem";
  import benefitsItem from "../components/benefits/benefitsItem";
  import benefitsWrapper from "../components/benefits/benefitsWrapper";
  import headerBg from "../components/app/headerBg";
  import SectionRigtLeft from "../components/home/sectionRigtLeft";

  export default {
    components: {
      SectionRigtLeft,
      headerBg,
      benefitsItem,
      benefitsWrapper,
      partnersItem,
      loading
    },
    async mounted() {
      await this.fetch()
    },
    data() {
      return {
        isLoading: false,
        data: {},
        page_settings: '',
        title: null,
        meta: [],
        og: []
      }
    },

    watch: {
      '$route.params.slug': {
        immediate: true,
        handler() {
          this.fetch()
        },
      },
    },

    methods: {
      fetch() {
        this.$store.dispatch('SET_LOADING_AXIOS', 2000)
        return new Promise((resolve, reject) => {
          GetRepository.getAbout()
            .then(resp => {
              this.isLoading = false;
              this.data = resp.data.data;
              this.page_settings = resp.data.data.page_settings;
              this.title = this.data.page.meta_title;
              this.og = this.data.meta.og.meta;
              resolve(resp);
            })
            .catch(err => {
              reject(err);
            });
        });
      },
    },

    metaInfo() {
      return {
        title: this.title,
        meta: this.og
      }
    },
  }
</script>

<style scoped lang="scss">
  @import "../assets/scss/index";

  .about {
    @include sectionGlobal;

    & .item {
      @include partnerItemImg
    }
  }
</style>