<template>
    <section class="product_wrapper">

        <div class="info_intro">

            <div class="title">
                <h3 v-html="title"></h3>
            </div>

            <div class="description">
                <p v-html="description"></p>
            </div>

        </div>
        <div class="wrapper_card">

            <slot />

        </div>
    </section>
</template>

<script>
    export default {
        props: ['title', 'description'],
        name: "benefitsWrapper"
    }
</script>

<style scoped lang="scss">
    @import "../../assets/scss/index";

    .product_wrapper {
        margin: 0 20px;
        @include sectionPadding;

        & .info_intro {
            text-align: center;
            margin: 10px 0 70px;

            & .title {
                & h3 {
                    @include title
                }
            }

            & .description {
                & p {
                    @include description
                }
            }
        }

        & .wrapper_card {
            margin: 0 auto;
            justify-content: space-around;
            display: flex;
            flex-wrap: wrap;

            @media (max-width: 1200px) {
                justify-content: center;
            }

        }


    }
</style>
