<template>
    <img :src="this.img" :alt="this.title">
</template>

<script>
    export default {
        props: ['title', 'img'],
        name: "partnersWrapper"
    }
</script>

<style scoped lang="scss">
    @import "../../assets/scss/index";

    img {
        object-fit: contain !important;
        margin: 25px 30px;
        max-width: 100%;
        max-height: 100%;
        opacity: 0.6 !important;
        transition: $transitionLink;

        &:hover {
            transition: $transitionLink;
            opacity: 1 !important;
        }

        @media (max-width: 769px) {
            max-width: 50%;
            max-height: 50%;
        }

    }
</style>